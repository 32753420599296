import { useEffect } from 'react';
import styles from './PaymentCallbackPage.module.css';
import { useSearchParams } from 'react-router-dom';

const PaymentCallbackPage = () => {
    const [searchParams] = useSearchParams();
    let params = Object.fromEntries(searchParams);
    // const handleUrlChange = () => {
    //     // const currentUrl = window.location.href;
    //     window.parent.postMessage(params?.status);
    // };
    useEffect(() => {
       setTimeout(() => {
        emitMsg()
       }, 2000);
        // Listen for URL changes and call the handleUrlChange function
        // window.addEventListener('hashchange', handleUrlChange);
        // window.addEventListener('popstate', handleUrlChange);

        // // Clean up the event listeners when the component unmounts
        // return () => {
        //     window.removeEventListener('hashchange', handleUrlChange);
        //     window.removeEventListener('popstate', handleUrlChange);
        // };
    }, []);
    const emitMsg = () => {
        // const msgHandler=new MessageChannel().port1
        // // const currentUrl = window.location.href;
        // msgHandler?.postMessage('hello1');
        window.messageHandler?.postMessage(JSON.stringify({status:params.status,transaction_id:params.transaction_id}));

        // window.parent?.postMessage('hello3');

    }
    return (
        <div className={styles.img_wrapper}>
            <div className={`${styles.suc_msg_wrapper}`}>
                {params.status === 'success' ? <div className={styles.card}>
                    <div onClick={()=>{
                         navigator.clipboard
                         .writeText(JSON.stringify(params))
                    }} style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: "0 auto" }}>
                        <i style={{ color: '#88b14b' }}>✓</i>
                    </div>
                    <h1 style={{ color: '#88b14b' }} className='  my-4'>Payment Successful</h1>
                    <p className={styles.secondary_text}>Thank you for your payment.
                        <br /> Enjoy Our Services! </p>
                    {/* <button className='btn-success btn' onClick={emitMsg}> done</button> */}
                </div> :
                    <div className={styles.card} >
                        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: "0 auto" }}>
                            <span className='text-danger d-flex justify-content-center align-item-center mt-5' style={{ fontSize: '7rem' }}>x</span>
                        </div>
                        <h1 className='text-danger my-3'>Payment Failed</h1>
                        <p className={styles.secondary_text}>We were unable to process your payment.<br />
                            Please review your payment information</p>
                        {/* <button className='btn-success btn' onClick={emitMsg}>done</button> */}
                    </div>}
            </div>
            {/* {params.isSuccess==='true'?<img className='w-100' src={success_img} alt=''/> 
            : <img className='w-100' src={failure_img} alt=''/>} */}
        </div>
    );
}

export default PaymentCallbackPage;
