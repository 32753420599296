const months = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];

export const formatDate = (date, dateFormat) => {
    let dayTime = date.getHours() >= 12 ? 'PM' : 'AM';
    let month = months[date.getMonth()];
    let day = date.getDate();
    let year = date.getFullYear();
    let hour = date.getHours() >= 12 ? date.getHours() - 12 : date.getHours();
    let minutes = date.getMinutes().length === 1 ? 0 + '' + date.getMinutes() : date.getMinutes();
    hour=hour?.toString().length===1?"0"+hour:hour;
    minutes=minutes?.toString().length===1?"0"+minutes:minutes;
    day=day?.toString().length===1?"0"+day:day;
    if (dateFormat === 'date') {
        return `${date.getMonth()+1}/${day}/${year}`
    }
    else if (dateFormat === 'date&time') {

        return `${month} ${day} ${year} at ${hour}: ${minutes} ${dayTime}`
    }
    else if (dateFormat === 'time') {
        return `${hour}: ${minutes} ${dayTime}`
    }
}