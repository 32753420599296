import { useEffect, useState } from 'react';
import styles from './bundleDetails.module.css';
import axios from 'axios';
import { json, useNavigate, useSearchParams } from 'react-router-dom';
import check_mark from '../Images/Group 3.svg'
import { formatDate } from '../../shared/helpers';
import { DownloadApp } from './downloadApp';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from '@mui/material';
const BundleDetailsSec = ({ bundle }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [errMsg, setErrMsg] = useState('');
    const [bundleDetails, setBundleDetails] = useState({});
    const [isAgreedTerms, setIsAgreedTerms] = useState(false);
    const navigate = useNavigate()

    const allParams = atob(Object.fromEntries(searchParams).payload).split("&");
    // console.log(btoa("amountCents=0&firstName=firstIka&lastName=lastIka&email=ika5@ika5.com&expiryMonth=12&packageName=H1&uuid=d4458b98-d674-411c-a295-b2e5c704f6ac")
    // );
    useEffect(() => {
        allParams.forEach(el => {
            bundleDetails[el.split('=')[0]] = el.split('=')[1]
        })
        setBundleDetails({ ...bundleDetails })
        if (bundleDetails.amountCents === "0") {
            window.messageHandler?.postMessage(JSON.stringify({ status: "success", transaction_id: '0' }));
        }
    }, []);

    const redirectToPaymentPage = async () => {
        if (!bundleDetails.amountCents) {
            setErrMsg('Payment amount is required')
        }
        if (!bundleDetails.email) {
            setErrMsg('Email amount is required')
        }
        const body = {
            "amount": +bundleDetails.amountCents / 100,
            firstName: bundleDetails.firstName,
            lastName: bundleDetails.lastName,
            "email": bundleDetails.email,
            station: 'ikarus'
        }
        setIsLoading(true)
        try {
            const res = await axios.post("https://api.payment.ikaruselectric.com/Transaction/create-Transaction/", body,
                { headers: { 'Authorization': 'Token 87b19078d33d000f74d7735d8f4a465158051df17360220fc218ddab8ffb08ea' } });
            // setPaymobUrl(res.data.url);
            const redirectURL = `/assets?sessionId=${res.data.session_id}`;
            // Perform the redirect
            setIsLoading(false)
            window.location.href = redirectURL
            // navigate(redirectURL);
            // window.location.href = res.data.url
        } catch (e) {
            console.log(e);
            if (e.response.status === 500) {
                setErrMsg('internal server error')
            } else if (e.response.status === 404) {
                setErrMsg('not found')

            } else {
                setErrMsg('Something went wrong,please try again')
            }
        }
        setIsLoading(false)
    }

    return (
        <div className={styles.bundle_details_sec_wrapper}>

            <div className={styles.gradient_text} onClick={() => navigator.clipboard
                .writeText(allParams)}>
                {bundleDetails.packageName}
            </div>
            <div style={{ fontWeight: '500' }} className='fs-3 my-2' >
                Let's make payment
                <div className={styles.secondary_text} style={{ fontSize: '16px' }}>
                    Initiate the Payment Process: Navigate Towards a Successful and Hassle-Free Transaction Completion
                </div>
            </div>


            <div className='fs-2 fw-bold'>
                {+bundleDetails.amountCents / 100}
                <span className={styles.secondary_text} style={{ fontSize: "20px" }} >EGP</span>
            </div>
            <div className={styles.secondary_text} style={{ fontSize: '16px' }}>
                Powerful Charging Flexibility for Every Adventure
            </div>
            <div className={styles.package_info_wrapper}>
                {/* <div className='d-flex align-items-center mt-3'><img src={check_mark} alt='' className='me-2 my-2' style={{ width: '14px' }} /><div><span style={{ fontWeight: '500' }} >{bundle?.electric_KW} KW</span> for 30 days</div></div> */}
                <div className='d-flex align-items-center mt-2'><img src={check_mark} alt='' className='me-2 my-2' style={{ width: '14px' }} /><div><span style={{ fontWeight: '500' }} >Valid until</span> {formatDate(new Date(new Date().setMonth(new Date().getMonth() + +bundleDetails.expiryMonth)), 'date')}
                </div></div>
                {/* <div className='d-flex align-items-center mt-2'><img src={check_mark} alt='' className='me-2 my-2' style={{ width: '14px' }} /><div>priority in busy stations</div></div> */}
                <div className='d-flex align-items-center mt-2'><img src={check_mark} alt='' className='me-2 my-2' style={{ width: '14px' }} /><div>24/7 customer support</div></div>
            </div>
            {/* <div className={styles.secondary_text}>
                {bundle?.description}
            </div> */}



            <div className='mt-2'>
                <input checked={isAgreedTerms}
                    style={{ width: '1rem', height: '1rem', marginRight: '.5rem' }}
                    onChange={() => {
                        setIsAgreedTerms(!isAgreedTerms)
                    }} type='checkbox' />
                I agree to <a href="/assets/terms.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>

            </div>

            <button disabled={isLoading || !isAgreedTerms} onClick={() => { redirectToPaymentPage() }}
                className={styles.payment_button}>{isLoading ? <CircularProgress sx={{ color: '#fff', width: '20px !important', height: '20px !important' }} /> : ''} <>
                    Pay Now</></button>
            <div className='d-flex justify-content-between w-100 my-2 pe-2'>
                <span style={{ fontWeight: '500' }}>Powered By</span>
                <div className='d-flex justify-content-between '>  <img src='/assets/images/visa.png' alt='' style={{ width: '2rem', margin: '0 8px' }} />
                    <img src='/assets/images/miza.png' alt='' style={{ width: '2rem', margin: '0 8px' }} />
                    <img src='/assets/images/master_card.png' alt='' style={{ width: '2rem', margin: '0 8px' }} /></div>
            </div>
            <div className={styles.bundel_details_download_app_buttons}><DownloadApp isRenderedFromDetailsSec={true} /></div>
            <Snackbar
                open={!!errMsg}
                autoHideDuration={2000}
                onClose={() => { setErrMsg('') }}
                message={errMsg}
            />
        </div>
    );
}

export { BundleDetailsSec };
