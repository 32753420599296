import styles from "./PaymentOptionsPage.module.css";
import Group1 from "../Images/Group1.jpg";
import Group2 from "../Images/ikarus_logo.svg";

const Header = () => {
  return (
    <div className={styles.header_wrapper}>
      <div className={styles.images_wrapper}>
        <div className={`${styles.header_icon} me-2`}>
          <img src={Group1} alt="Image1" />
        </div>
        <div className={styles.header_secondary_icon}>
          <img src={Group2} alt="Image2" />
        </div>
      </div>
   
    </div>
  );
};

export { Header };
