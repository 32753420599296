import {  useState } from "react";
import { Header } from "../paymentOptionsPage/header";
import styles from './bundleDetails.module.css';
import { BundleDetailsSec } from "./bundleDetailsSec";
import { ImgSec } from "./imgSec";
import { Snackbar } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const BundleDetails = () => {
    const [searchParams] = useSearchParams();
    const [errMsg, setErrMsg] = useState('');
//     console.log(atob(Object.fromEntries(searchParams).payload),4);
// console.log(btoa('amountCents=100000&firstName=firstIka&lastName=lastIka&email=ika5@ika5.com&expiryMonth=12&packageName=H1&uuid=d4458b98-d674-411c-a295-b2e5c704f6ac 4'),4);

   
    return (
        <div>
            {/* {isLoading && <CircularProgress className='centered_progress' />} */}
            <Header />
            <div className={styles.bundle_details_wrapper}>
                <BundleDetailsSec  />
                <ImgSec />
            </div>
            <Snackbar
                open={!!errMsg}
                autoHideDuration={2000}
                onClose={() => { setErrMsg('') }}
                message={errMsg}
            />
        </div>
    );
}

export default BundleDetails;
