import React from 'react';

const Notfound = () => {
    return (
        <div style={{
            display:'flex',
            justifyContent:'center',
            flexDirection:'column',
            position: 'absolute',
            left: '50%',
            top: '50%',
            width:'100%',
            // marginTop:'150%',
            transform: 'translate(-50%,50%)'}}>
     <h1 style={{color:'#CC9AA6',textAlign:'center'}}> Page Not Found</h1>
<section className="error-container">
  <span className="four"><span className="screen-reader-text">4</span></span>
  <span className="zero"><span className="screen-reader-text">0</span></span>
  <span className="four"><span className="screen-reader-text">4</span></span>
  {/*  */}
</section>

        </div>
    );
}

export  {Notfound};
