import styles from './bundleDetails.module.css';
import google_play_logo from "../Images/Google_Play_logo.svg";
import apple_logo from "../Images/Apple logo.png";

const DownloadApp = ({isRenderedFromDetailsSec=false}) => {
    return (
        <div className={`${!isRenderedFromDetailsSec&&styles.download_apk_sec}`}>
     {!isRenderedFromDetailsSec&&<>   <div className='fs-5 mb-2 text-light'>
            Download the App
        </div>
        <div className='text-light' style={{ fontSize: '12px', maxWidth: '60%' }}>
            Discover seamless EV charging with our app. Find stations,
            initiate sessions, and enjoy exclusive perks. Download now to power your sustainable journey.
        </div>
        </>
}
        {/* buttons */}
    
   
        <div className={`${styles.buttons_wrapper}`}>
                      {/* google_play_logo */}
          <button className={styles.button}>
                    <img className={styles.google_play_logo} src={google_play_logo} alt='' />
                    <div className={styles.button_text_content} >
                        <span style={{ fontSize: '10px' }}>
                            Get it on
                        </span>
                        <span style={{ fontSize: '16' }}>
                            Google Play
                        </span>
                    </div>
                </button>
                {/* apple_play_logo */}
                <button className={styles.button}>
                    <img className={styles.google_play_logo} src={apple_logo} alt='' />
                    <div className={styles.button_text_content} >
                        <span style={{ fontSize: '10px' }}>
                            Get it on
                        </span>
                        <span style={{ fontSize: '16' }}>
                            Google Play
                        </span>
                    </div>
                </button>   
        </div>
        </div>
    );
}

export  {DownloadApp};
