import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import BundleDetails from "./components/bundleDetails/bundleDetails";
import PaymentOptionsPage from "./components/paymentOptionsPage/PaymentOptionsPage";
import PaymentCallbackPage from "./components/paymentCallbackPage/PaymentCallbackPage";
import { Notfound } from "./components/errPages/404";

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          {/* <Route path="/" element={<PaymentOptionsPage />} /> */}
          <Route path="/bundle-details" element={<BundleDetails />} />
          <Route path="/payment-callback-page" element={<PaymentCallbackPage />} />
          <Route path={'*'} element={ <Notfound/>} />  
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
