import styles from './bundleDetails.module.css';
import ikarus_img from '../Images/Ikarus_img.png';
import ikarus_logo from '../Images/ikarus_logo.svg';
import { DownloadApp } from './downloadApp';

const ImgSec = () => {
    return (
        <div className={styles.img_sec_wrapper}>
            <img className={styles.ikarus_logo} src={ikarus_logo} alt='' />
            <img src={ikarus_img} alt='' className='h-100' />
           <DownloadApp/>
        </div>
    );
}

export { ImgSec };
