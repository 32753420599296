import React from 'react';

const ErrPage = () => {
    return (
        <div className='err_page_wrapper'>
            <div className="fof">
                <h1>
                    Something Went Wrong Please Try Again
                </h1>
            </div>
        </div>

    );
}

export { ErrPage };
